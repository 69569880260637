<template>
  <div class="onboarding-content">
    <div class="inner">
      <img
        class="graphic"
        :src="image"
      >
      <base-title :level="1">
        {{ title }}
      </base-title>

      <div class="body">
        <slot />
      </div>

      <base-group
        class="footer"
        align="center"
        spacing="medium"
      >
        <base-button
          v-if="allowBack"
          label="Previous"
          link
          @click="$emit('back')"
        />
        <base-button
          type="secondary"
          :label="continueButtonLabel"
          icon="arrow-ios-forward-outline"
          icon-position="right"
          @click="$emit('continue')"
        />
      </base-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    allowBack: {
      type: Boolean,
      default: true,
    },
    continueButtonLabel: {
      type: String,
      default: 'Continue',
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.body {
  margin-bottom: 32px;
}

.graphic {
  width: 100%;
  max-width: 500px;
  margin-bottom: 48px;
}
</style>
