<template>
  <base-page-fullscreen
    button-label="Skip All"
    @click-button="finish"
  >
    <transition-fade>
      <onboarding-content
        v-if="step === 1"
        key="1"
        title="Get your employees involved"
        :image="require('@/assets/images/graphic/welcome.svg')"
        :allow-back="false"
        @continue="step = 2"
      >
        <base-paragraph>
          Involved employees are your company's ambassadors. They experience much more happiness
          at work and are therefore more productive, better in balance and less often ill.
        </base-paragraph>
      </onboarding-content>

      <onboarding-content
        v-else-if="step === 2"
        key="2"
        title="Receive feedback from your team"
        :image="require('@/assets/images/graphic/feedback.svg')"
        @continue="step = 3"
        @back="step = 1"
      >
        <base-paragraph>
          The times of boring traditional surveys are over. You will have an answer within seconds.
          Employees provide weekly answers to a single question that is relevant at the time.
        </base-paragraph>
      </onboarding-content>

      <onboarding-content
        v-else-if="step === 3"
        key="3"
        title="Analyse and compare insights"
        :image="require('@/assets/images/graphic/analyse.svg')"
        @continue="step = 4"
        @back="step = 2"
      >
        <base-paragraph>
          View the given answers in real time or via a report once all answers have been received.
          Repetitive questions show the trend development over the period.
        </base-paragraph>
      </onboarding-content>

      <onboarding-content
        v-else-if="step === 4"
        key="4"
        title="Plan your subjects and questions"
        :image="require('@/assets/images/graphic/subjects.svg')"
        @continue="step = 5"
        @back="step = 3"
      >
        <base-paragraph>
          If you want a quick answer to a pressing question, you should ask it in between.
          You can easily create different question types and save them as template.
        </base-paragraph>
      </onboarding-content>

      <onboarding-content
        v-else-if="step === 5"
        key="5"
        title="Or let AI choose relevant questions"
        :image="require('@/assets/images/graphic/learning.svg')"
        continue-button-label="Get InVolved"
        @continue="finish"
        @back="step = 4"
      >
        <base-paragraph>
          Make it easy for yourself and let our AI come up with a follow-up question
          for the coming week. You always keep control over which question is asked.
        </base-paragraph>
      </onboarding-content>
    </transition-fade>
  </base-page-fullscreen>
</template>

<script>
/* Import Mutations */
import UPDATE_USER_MUTATION from '@/graphql/mutations/UpdateUser.gql';

/* Import Components */
import OnboardingContent from '@/components/modules/onboarding/OnboardingContent.vue';

export default {
  components: {
    OnboardingContent,
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      step: 1,
    };
  },
  methods: {
    async finish() {
      // Set onboarding_completed to true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: { id: this.userId, onboarding_completed: true },
        })
        .finally(() => {
          // Open Dashboard
          this.$router.push({ name: 'dashboard' });
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
